@import '../../styles/variables.scss';

.navbarContainer {
  background-color: transparent;
  transition: 250ms ease-in background-color;
  will-change: transform;
  backface-visibility: hidden;
  &.navbarOpaque {
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.85);
  }
  .navbarToggler {
    top: 0;
    bottom: 0;
    position: absolute;
  }
  .navbarLogoContainer {
    height: 50px;
    width: 150px;
    .navbarLogo {
      transition: 250ms ease-in opacity;
      &.imageAlter {
        opacity: 0;
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
  .navbarButton {
    font-size: 15px;
    background: $sizzlyPrimary;
    color: white;
    border: 2px solid $sizzlyPrimary;
    &:hover,
    &:active {
      background: $sizzlyPrimaryDark;
      border: 2px solid $sizzlyPrimaryDark;
    }
  }
  .navbarButtonSecondary {
    font-size: 15px;
    background: $sizzlyLight;
    color: $sizzlyPrimary;
    border: 2px solid $sizzlyPrimary;
    &:hover,
    &:active {
      background: $sizzlyPrimaryLight;
      border: 2px solid $sizzlyPrimary;
    }
  }
}

.earthContent {
  @media (max-width: 992px) {
    color: white !important;
  }
  @media (min-width: 992px) {
    color: $sizzlyPrimary !important;
  }
}

@media (max-width: 992px) {
  .offcanvasSmall {
    margin: auto;
  }
}

@media (min-width: 992px) {
  .earth {
    height: 40px;
  }
}

.fillSvg {
  filter: brightness(0) invert(1);
  z-index: -1;
}
