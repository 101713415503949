@import '../../styles/variables.scss';

.dotFlash {
  position: absolute;
  left: 50vw;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  text-align: center;
  background-color: $sizzlyPrimaryWineRed;
  color: $sizzlyPrimaryWineRed;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.2s;
}

.dotFlash::before,
.dotFlash::after {
  content: '';
  position: absolute;
  top: 0;
}

.dotFlash::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $sizzlyPrimaryWineRed;
  color: $sizzlyPrimaryWineRed;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dotFlash::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $sizzlyPrimaryWineRed;
  color: $sizzlyPrimaryWineRed;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0.5s;
}

@keyframes dotFlashing {
  0% {
    background-color: $sizzlyPrimaryWineRed;
  }
  50%,
  100% {
    background-color: $sizzlyPrimaryWineRedLight;
  }
}

.loadingDiv {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  margin-top: 50vh;
}

.displayNone {
  display: none;
}
